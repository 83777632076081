/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12 sm12 lg6 xl6 text-center align-self-center>
      <v-img src="@/assets/images/login.png" height="100vh" cover></v-img>
    </v-flex>
    <V-flex xs12 sm12 lg5 xl4 px-4 px-sm-0>
      <v-layout wrap justify-center pt-xl-12>
        <v-flex xs12 sm8 lg8>
          <v-layout wrap justify-start pt-xl-12 mt-lg-12>
            <v-flex xs12 pt-12>
              <span class="title-main">
                {{ $t("pages.login.heading") }}
              </span>
              <br />
              <span class="subtitle-2">
                {{ $t("pages.login.sub_heading") }}
              </span>
            </v-flex>
            <v-flex xs12 sm10 pt-12 py-xl-12>
              <v-form ref="form" lazy-validation>
                <v-layout wrap justify-center pt-xl-12>
                  <v-flex xs12 pt-4>
                    <p class="input-label">{{ $t("form.email") }}</p>
                    <v-text-field
                      dense
                      outlined
                      v-model="email"
                      :rules="[RULES.REQUIRED]"
                    />
                  </v-flex>
                  <v-flex xs12 pt-4>
                    <p class="input-label">{{ $t("form.password") }}</p>
                    <v-text-field
                      dense
                      outlined
                      v-model="password"
                      :rules="[RULES.REQUIRED]"
                      :type="viewPassword ? 'text' : 'password'"
                      :append-icon="viewPassword ? `mdi-eye` : 'mdi-eye-off'"
                      @click:append="viewPassword = !viewPassword"
                    />
                  </v-flex>
                  <v-flex xs12 pt-4 text-right>
                    <router-link :to="{ name: 'forgot-password' }">
                      <p class="input-label primary--text">
                        {{ $t("pages.forgot_password.title") }}?
                      </p>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 pt-6 pt-xl-12>
                    <AppButton
                      block
                      custom-class="py-5"
                      :label="$t('buttons.login')"
                      @submit="login"
                    />
                  </v-flex>
                  <!--                  <v-flex xs12 pt-6 pt-xl-12>-->
                  <!--                    <div class="hr-sect text-capitalize">-->
                  <!--                      {{ $t("words.or") }}-->
                  <!--                    </div>-->
                  <!--                  </v-flex>-->
                  <!--                  <v-flex xs12 pt-6 pt-xl-12>-->
                  <!--                    <v-layout wrap justify-space-between>-->
                  <!--                      <v-flex xs5>-->
                  <!--                        <AppButton block outlined large color="#A6AEBE">-->
                  <!--                          <template #content>-->
                  <!--                            <v-layout wrap justify-start>-->
                  <!--                              <v-flex xs3>-->
                  <!--                                <v-img-->
                  <!--                                  src="@/assets/images/facebook.png"-->
                  <!--                                  height="20px"-->
                  <!--                                  contain-->
                  <!--                                ></v-img>-->
                  <!--                              </v-flex>-->
                  <!--                              <v-flex xs9 pl-2>-->
                  <!--                                <span class="title-3">Facebook</span>-->
                  <!--                              </v-flex>-->
                  <!--                            </v-layout>-->
                  <!--                          </template>-->
                  <!--                        </AppButton>-->
                  <!--                      </v-flex>-->
                  <!--                      <v-flex xs5>-->
                  <!--                        <AppButton block outlined large color="#A6AEBE">-->
                  <!--                          <template #content>-->
                  <!--                            <v-layout wrap justify-start>-->
                  <!--                              <v-flex xs3>-->
                  <!--                                <v-img-->
                  <!--                                  src="@/assets/images/google.png"-->
                  <!--                                  height="20px"-->
                  <!--                                  contain-->
                  <!--                                ></v-img>-->
                  <!--                              </v-flex>-->
                  <!--                              <v-flex xs9 pl-2>-->
                  <!--                                <span class="title-3">Google</span>-->
                  <!--                              </v-flex>-->
                  <!--                            </v-layout>-->
                  <!--                          </template>-->
                  <!--                        </AppButton>-->
                  <!--                      </v-flex>-->
                  <!--                    </v-layout>-->
                  <!--                  </v-flex>-->
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!--      <v-layout wrap justify-center pt-xl-12 mt-lg-12>-->
      <!--        <v-flex xs12 sm8 align-self-baseline pt-12 mt-12>-->
      <!--          <span class="subtitle-2">{{ $t("app.copyright") }}</span>-->
      <!--        </v-flex>-->
      <!--      </v-layout>-->
    </V-flex>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" bottom right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";
import { ROLES } from "@/constants/user-roles";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data: () => ({
    RULES,
    email: null,
    password: null,
    message: null,
    showSnackbar: false,
    snackbarColor: "error",
    viewPassword: false,
  }),
  computed: {
    ...mapGetters({
      fcmToken: "app/getFcmToken",
    }),
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) return;
      const data = {
        email: this.email,
        password: this.password,
        device_name: "web",
        device_token: this.fcmToken,
      };
      this.$store
        .dispatch("app/login", data)
        .then((res) => {
          if (res.user.role === ROLES.ADMIN)
            this.$router.push({ name: "dashboard" });
          else if (res.user.role === ROLES.HOSPITAL) {
            this.$store.dispatch("app/resetApp");
            this.$router.push({ name: "services" });
          } else {
            this.message = "Please use mobile app to login to your account";
            this.snackbarColor = "error";
            this.showSnackbar = true;
          }
        })
        .catch((err) => {
          this.message = err.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
/*.v-text-field--outlined >>> fieldset {*/
/*  border-color: #c0cbe0 !important;*/
/*}*/

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
