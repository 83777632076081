var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        {
          attrs: {
            xs12: "",
            sm12: "",
            lg6: "",
            xl6: "",
            "text-center": "",
            "align-self-center": "",
          },
        },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/login.png"),
              height: "100vh",
              cover: "",
            },
          }),
        ],
        1
      ),
      _c(
        "V-flex",
        {
          attrs: {
            xs12: "",
            sm12: "",
            lg5: "",
            xl4: "",
            "px-4": "",
            "px-sm-0": "",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-center": "", "pt-xl-12": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", lg8: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        wrap: "",
                        "justify-start": "",
                        "pt-xl-12": "",
                        "mt-lg-12": "",
                      },
                    },
                    [
                      _c("v-flex", { attrs: { xs12: "", "pt-12": "" } }, [
                        _c("span", { staticClass: "title-main" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("pages.login.heading")) + " "
                          ),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.login.sub_heading")) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm10: "",
                            "pt-12": "",
                            "py-xl-12": "",
                          },
                        },
                        [
                          _c(
                            "v-form",
                            { ref: "form", attrs: { "lazy-validation": "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "justify-center": "",
                                    "pt-xl-12": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pt-4": "" } },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.email"))),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          rules: [_vm.RULES.REQUIRED],
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pt-4": "" } },
                                    [
                                      _c("p", { staticClass: "input-label" }, [
                                        _vm._v(_vm._s(_vm.$t("form.password"))),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          rules: [_vm.RULES.REQUIRED],
                                          type: _vm.viewPassword
                                            ? "text"
                                            : "password",
                                          "append-icon": _vm.viewPassword
                                            ? `mdi-eye`
                                            : "mdi-eye-off",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.viewPassword = !_vm.viewPassword
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "pt-4": "",
                                        "text-right": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "forgot-password" },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "input-label primary--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.forgot_password.title"
                                                    )
                                                  ) +
                                                  "? "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "pt-6": "",
                                        "pt-xl-12": "",
                                      },
                                    },
                                    [
                                      _c("AppButton", {
                                        attrs: {
                                          block: "",
                                          "custom-class": "py-5",
                                          label: _vm.$t("buttons.login"),
                                        },
                                        on: { submit: _vm.login },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor, bottom: "", right: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.showSnackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }